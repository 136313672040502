import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function TextRichBox({ descriptionValue, setDescriptionValue, label, id }: any) {
  return (
    <div className='textRichBoxCustomYM'>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <ReactQuill theme='snow' value={descriptionValue} onChange={setDescriptionValue} />
    </div>
  );
}

export default TextRichBox;
