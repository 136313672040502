import React, { useState } from 'react';
import TMDropZone from '../../../../components/common/YMDropZone.component';
import FormikInputBox from '../../../../components/common/FormikInputBox.component';
import { Formik, Form, FormikHelpers } from 'formik';
import { TeamPositionSelect } from '../../../../components/common/Dropdown.component';

interface Values {
  title: string;
  description: string;
  timeline: number; //days
}
const teams = [
  { value: 'Team Lead', label: 'Team Lead' },
  { value: 'Producer', label: 'Producer' },
  { value: 'Designer', label: 'Designer' },
  { value: 'Actor', label: 'Actor' },
];
function VirtualProductionForm({ setFormData }: any) {
  const [imglist, setImgList] = useState([]);
  const [ImageBlobs, setImageBlobs] = useState([]);
  const [positionSelectedList, setPositionSelectedList] = useState<Array<string> | null>(null);
  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
        timeline: 0,
      }}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<Values>) => {
        values['team'] = {};
        values['media'] = {
          images: ImageBlobs,
          youTube: values.ytLink
        };
        Object.keys(values).map((key) => {
          if (['Team Lead', 'Producer', 'Designer', 'Actor'].includes(key)) {
            values['team'][key] = values[key];
            delete values[key];
          }
        });
        console.log(values);
        setFormData(values);
      }}>
      <Form>
        <FormikInputBox id={'title'} type='text' label={'Title'} placeholder={'Castle Black'} />
        <FormikInputBox
          id='description'
          type={'text_area'}
          label={'Description'}
          placeholder={'Castle Black is a formidable stronghold ...'}
        />
        <FormikInputBox id='timeline' type={'number'} label={'Timeline (days)'} placeholder={'20'} />
        <TeamPositionSelect
          label={'Team Division'}
          options={teams}
          onChange={(e: any) => {
            console.log(e);
            let p: Array<any> = [];
            e.map(({ value }: any) => {
              p.push(value);
            });

            setPositionSelectedList(p);
          }}
        />
        {positionSelectedList
          ? positionSelectedList.map((key) => {
              return <FormikInputBox id={key} type={'text'} label={key} placeholder={`${key} name`} />;
            })
          : null}
        <FormikInputBox id='ytLink' type={'text'} label={'YouTube Link'} placeholder={'yt.com/ym'} />
        <TMDropZone setImgList={setImgList} imglist={imglist} ImageBlobs={ImageBlobs} setImageBlobs={setImageBlobs} />
        <button className='YMformikSumbit' type='submit'>
          Submit
        </button>
      </Form>
    </Formik>
  );
}

export default VirtualProductionForm;
