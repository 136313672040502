import React from 'react';
import YMContainer from '../../../components/container/YMContainer';

function RequestProposal() {
  return (
    <div className='requestAProposal animate__animated animate__fadeIn'>
      <div className='form'>
        <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSdIjhKljurVJ8vNvAHSLWfuqM9Sl8KAHYFC4qZ_D6iVZ9U6sw/viewform?embedded=true'>
          Loading…
        </iframe>
      </div>
      <img className='sideImage' src={`https://ym-website-videos.s3.ap-south-1.amazonaws.com/proposalbg.png`} />
    </div>
  );
}

export default RequestProposal;
