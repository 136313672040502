import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
function GoTo({ children, className, showInitArrow = true, onClick = () => {} }: any) {
  return (
    <div
      className={`${className} goto-arrow`}
      onClick={() => {
        onClick();
      }}
    >
      {children}

      <HiArrowNarrowRight
        className={`${showInitArrow ? 'show' : 'hide'} HiArrowRight animate__animated animate__lightSpeedInLeft`}
      />
    </div>
  );
}

export default GoTo;
