import React, { useEffect, useState } from "react";
import { getRealEstateDetails } from "../../services/api.service";
import { useParams } from "react-router-dom";
import loading from "../../assets/images/sample.jpeg";
function ModelViewer({ projectData }: { projectData?: any }) {
  const { projectId } = useParams();
  const [inProjectData, setProjectData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ModelViewerElement, setModelViewerElement] = useState<any>(null);
  const createMarkup = (html: any) => {
    return { __html: html };
  };
  const setProjectDetails = async (id: string) => {
    try {
      const response: any = await getRealEstateDetails(id);
      setProjectData(response);
      getButtons(response);
    } catch (error) {
      console.error(error);
    }
  };
  const getButtons = (response: any) => {
    let button: any = {};
    Object.keys(response).map((data: string, index: number) => {
      const keySplit = data.split("_");

      if (keySplit.includes("hotspot")) {
        const btnNumber = keySplit[1];
        const btnKey = keySplit[2];
        if (!Object.keys(button).includes(btnNumber)) {
          button[btnNumber] = {
            name: "",
            position: "",
            normal: "",
          };
        }
        button[btnNumber][btnKey] = response[data];
      }
    });
    console.log(button);
    let buttonStringElem = "";
    Object.keys(button).map((data, index) => {
      const { name, position, normal } = button[data];
      buttonStringElem += `<button
      id='Hotspot'
      slot='hotspot-${index}'
      data-position='${position}'
      data-normal='${normal}'
      data-visibility-attribute='visible'>
      <div id='HotspotAnnotation'>${name}</div>
    </button>`;
    });
    console.log(`response?.maxCameraOrbit`, response?.maxCameraOrbit);
    let fullModelViewerString = `<model-viewer
    camera-controls
    touch-action='pan-y'
    ${
      response?.skyBoxFileName
        ? `skybox-image='https://ym-real-estate.s3.ap-south-1.amazonaws.com/${response?.skyBoxFileName}'`
        : ""
    }
    loading="eager"
    src='https://ym-real-estate.s3.ap-south-1.amazonaws.com/${response?.modelGLBFilename}'
    min-camera-orbit='${response?.minCameraOrbit}'
    max-camera-orbit='${response?.maxCameraOrbit}'
    shadow-intensity='${response?.shadowIntensity}'
    min-field-of-view='2deg'
    id='ymModelViewer'>
    
    ${buttonStringElem}
    <div id="lazy-load-poster" slot="poster"></div>
    <div id="loadingPoster" slot="poster">
    <img src="https://ym-real-estate.s3.ap-south-1.amazonaws.com/ymLogo.gif" />
   </div>
    </model-viewer> `;
    console.log(fullModelViewerString);
    setModelViewerElement(fullModelViewerString);
  };

  useEffect(() => {
    if (!projectData) {
      setProjectDetails(projectId as any);
    } else {
      getButtons(projectData);
    }

    return () => {};
  }, []);
  // Handles loading the events for <model-viewer>'s slotted progress bar
  const onProgress = (event: any) => {
    console.log(event);
    const progressBar = event.target.querySelector(".progress-bar");
    const updatingBar = event.target.querySelector(".update-bar");

    if (event.detail.totalProgress === 0) {
      progressBar.style.display = "block";
      updatingBar.style.width = "0%";
    } else {
      updatingBar.style.width = `${event.detail.totalProgress * 100}%`;

      if (event.detail.totalProgress === 1) {
        setTimeout(() => {
          progressBar.style.display = "none";
        }, 500);
      }
    }
  };
  return (
    <div>
      <div
        style={
          inProjectData
            ? { width: "100%", height: "100vh", margin: "0 auto" }
            : { width: "95%", height: "75vh", margin: "0 auto" }
        }
        dangerouslySetInnerHTML={
          ModelViewerElement ? createMarkup(ModelViewerElement) : { __html: "" }
        }
      />
      <div className='progress-bar' slot='progress-bar'>
        <div className='update-bar'></div>
      </div>
    </div>
  );
}

export default ModelViewer;
