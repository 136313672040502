import React, { useState } from 'react';
import InputBox from '../../../../components/common/InputBox.component';
import TMDropZone from '../../../../components/common/YMDropZone.component';
import FormikInputBox from '../../../../components/common/FormikInputBox.component';
import { Formik, Field, Form, FormikHelpers } from 'formik';
interface Values {
  title: string;
  description: string;
}
function InteriorDesignForm({ setFormData }: any) {
  const [ImageBlobs, setImageBlobs] = useState([]);
  const [imglist, setImgList] = useState([]);

  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
      }}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<Values>) => {
        values['media'] = {
          images: ImageBlobs,
          youTube: values.ytLink
        };
        values['rooms'] = {
          bedrooms: values.bedrooms,
          hall: values.hall,
          kitchen: values.kitchen,
        };
        delete values['bedrooms'];
        delete values['hall'];
        delete values['kitchen'];
        console.log(values);
        setFormData(values);
      }}>
      <Form>
        <FormikInputBox id={'title'} type='text' label={'Title'} placeholder={'Castle Black'} />
        <FormikInputBox
          id='description'
          type={'text_area'}
          label={'Description'}
          placeholder={'Castle Black is a formidable stronghold ...'}
        />
        <FormikInputBox id='responsiblePerson' type={'text'} label={'Principal Designer'} placeholder={'John Snow'} />{' '}
        <FormikInputBox id='bedrooms' type={'number'} label={'Bedrooms'} placeholder={'20'} />
        <FormikInputBox id='hall' type={'number'} label={'Hall'} placeholder={'20'} />
        <FormikInputBox id='kitchen' type={'number'} label={'Kitchen'} placeholder={'20'} />
        <FormikInputBox id='ytLink' type={'text'} label={'YouTube Link'} placeholder={'yt.com/ym'} />
        <TMDropZone setImgList={setImgList} imglist={imglist} ImageBlobs={ImageBlobs} setImageBlobs={setImageBlobs} />
        <button className='YMformikSumbit' type='submit'>
          Submit
        </button>
      </Form>
    </Formik>
  );
}

export default InteriorDesignForm;
