import React from "react";
import { VIDEO } from "../../services/assets";
import YMContainer from "../../../components/container/YMContainer";
import Header from "../../../components/header/Header.component";
import Footer from "../../../components/footer/Footer.component";
import { useNavigate } from "react-router-dom";
import { route } from "../../services/route";

function Home() {
  const navigate = useNavigate();
  return (
    <div className='fp-home animate__animated animate__fadeIn'>
      <video className='background' muted autoPlay loop>
        <source src={VIDEO.YM_FRONT_VIDEO} type='video/mp4' />
      </video>

      <div className='content'>
        <YMContainer className='home-container'>
          <div className='mainTitle'>Timeless Innovations</div>
          <div className='section-items'>
            <ul>
              <li
                id='WORKS'
                onClick={() => {
                  navigate(route.WORKS);
                }}>
                WORKS
              </li>
              <li
                id='REAL_ESTATE'
                onClick={() => {
                  navigate(route.REAL_ESTATE);
                }}>
                REAL ESTATE
              </li>
              <li
                id='SERVICES'
                onClick={() => {
                  navigate(route.SERVICE);
                }}>
                SERVICES
              </li>
              <li
                id='ABOUT_US'
                onClick={() => {
                  navigate(route.ABOUT);
                }}>
                ABOUT US
              </li>
              <li
                id='CONTACT'
                onClick={() => {
                  navigate(route.CONTACT);
                }}>
                CONTACT
              </li>
            </ul>
          </div>
        </YMContainer>
      </div>
    </div>
  );
}

export default Home;
