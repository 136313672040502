import { BehaviorSubject } from 'rxjs';
export interface ToastInterface {
  id: number;
  type: 'success' | 'warn' | 'normal' | 'error' | 'normal-with-btn';
  title?: string;
  message?: string;
  onClick?: () => void;
  toastSize: 'toastSmall' | 'toastLarge';
}
export interface TosterInterface {
  position: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';
  autoDelete: boolean | true;
  toastList: Array<ToastInterface>;
}

const toaster = new BehaviorSubject<TosterInterface | []>([]);
const toasterService = {
  set: (state: any) => {
    let li: any = [];
    const reState = state;
    toaster.asObservable().subscribe((data: any) => {
      li = data;
    });
    reState.id = li.length + 1;
    li.push(state);
    toaster.next(li);
  },
  get: () => toaster.asObservable(),
  update: (list: any) => {
    toaster.next(list);
  },
};
export default toasterService;
