import logo from '../../assets/images/ym_logo.jpeg';
import work_1 from '../../assets/images/works/1.jpg';
import work_2 from '../../assets/images/works/2.png';
import work_3 from '../../assets/images/works/3.png';
import work_4 from '../../assets/images/works/4.png';
import work_5 from '../../assets/images/works/5.png';
import work_6 from '../../assets/images/works/6.png';
import work_7 from '../../assets/images/works/7.png';
import work_8 from '../../assets/images/works/8.png';
import work_9 from '../../assets/images/works/9.png';
import work_10 from '../../assets/images/works/10.png';
import work_11 from '../../assets/images/works/11.png';
import work_12 from '../../assets/images/works/12.png';
import work_13 from '../../assets/images/works/13.png';
import work_14 from '../../assets/images/works/14.png';
import work_15 from '../../assets/images/works/15.png';
import warningIcon from '../../assets/images/toast/warning.svg';
import dangerIcon from '../../assets/images/toast/danger.svg';
import successIcon from '../../assets/images/toast/success.svg';

const VIDEO = {
  YM_FRONT_VIDEO: `https://ym-website-videos.s3.ap-south-1.amazonaws.com/ymFrontVideo.mp4`,
  YM_CONTACT_VIDEO: `https://ym-website-videos.s3.ap-south-1.amazonaws.com/contact.mp4`,
  YM_ABOUT_VIDEO: `https://ym-website-videos.s3.ap-south-1.amazonaws.com/about.mp4`,
  YM_SERVICE_VIDEO: `https://ym-website-videos.s3.ap-south-1.amazonaws.com/services.mp4`,
};
const YM_LOGO = logo;
const WORK_THUMBNAILS: Record<string, any> = {
  work_1,
  work_2,
  work_3,
  work_4,
  work_5,
  work_6,
  work_7,
  work_8,
  work_9,
  work_10,
  work_11,
  work_12,
  work_13,
  work_14,
  work_15,
  work_16: work_1,
  work_17: work_1,
};
const TOAST = {
  WARNING: warningIcon,
  DANGER: dangerIcon,
  SUCCESS: successIcon,
};
export { VIDEO, YM_LOGO, WORK_THUMBNAILS, TOAST };
