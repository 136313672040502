import React from 'react';
import { VIDEO } from '../../services/assets';
import YMContainer from '../../../components/container/YMContainer';
import GoTo from '../../../components/rightArrow/GoTo.component';
import { useNavigate } from 'react-router-dom';
import { route } from '../../services/route';
import Creator from '../../../components/creatorCredits/Creator.component';
import GeneralFooter from '../../../components/footer/GeneralFooter.component';

function Contact() {
  const navigate = useNavigate();
  return (
    <div className='fp-contact animate__animated animate__fadeIn'>
      <video className='background' muted autoPlay loop>
        <source src={VIDEO.YM_CONTACT_VIDEO} type='video/mp4' />
      </video>

      <div className='content'>
        <YMContainer className='contact-container'>
          <div className='body'>
            <div className='mainTitle'>Ready to discuss your next project?</div>
            <div className='contactBody'>Send us an email to get the conversation started.</div>
            <div className='contactBody email'>ymimagineers@gmail.com</div>
            <div className='contactBody desc'>
              If you need guidance on what information should be provided, simply fill out our request a proposal form
              below.
            </div>
            <GoTo
              className='contactBody fillForm'
              onClick={() => {
                navigate(route.REQUEST_A_PROPOSAL);
              }}>
              <div>Fill out form</div>
            </GoTo>
          </div>
          {/* <Creator /> */}
          {/* <GeneralFooter className='contact-footer' /> */}
        </YMContainer>
      </div>
    </div>
  );
}

export default Contact;
