import React from 'react';
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi';
function GoBack({ children, className, showInitArrow = true, onClick = () => {} }: any) {
  return (
    <div
      className={`${className} goto-arrow`}
      onClick={() => {
        onClick();
      }}>
      <HiArrowNarrowLeft
        className={`${showInitArrow ? 'show' : 'hide'} HiArrowRight animate__animated animate__lightSpeedInRight`}
      />
      {children}
    </div>
  );
}

export default GoBack;
