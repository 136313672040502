import React, { useState } from "react";

function GridImage({ images }: any) {
  const [lightboxImage, setLightboxImage] = useState("");
  const [lightboxAltImage, setLightboxAltImage] = useState("");

  const openLightbox = (src: string, alt: string) => {
    setLightboxImage(src);
    setLightboxAltImage(alt);
  };

  const closeLightbox = () => {
    setLightboxImage("");
    setLightboxAltImage("");
  };

  return (
    <div className='gridGallery'>
      <div className='gallery'>
        {images.map(({ original, thumbnail }: any, index: number) => (
          <button
            type='button'
            className='gallery__item'
            key={index}
            onClick={() => openLightbox(original, `${index}_image`)}>
            <img className='gallery__image' src={original} alt={`${index}_image`} />
          </button>
        ))}
      </div>

      {lightboxImage && (
        <div className='lightbox'>
          <button type='button' className='lightbox__close-button' onClick={closeLightbox}>
            &times;
          </button>
          <img className='lightbox__image' src={lightboxImage} alt={lightboxAltImage} />
          <button type='button' className='lightbox__bg' onClick={closeLightbox} />
        </div>
      )}
    </div>
  );
}
export default GridImage;
