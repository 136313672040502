export function formatText(string: string) {
  string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
  string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
  string = string[0].toUpperCase() + string.slice(1);
  string = string.replace(/And/g, 'and');
  return string;
}

export function reSizeImage(img: any, maxWidth: number, maxHeight: number) {
  var canvas = document.createElement('canvas');
  const { width, height } = calculateAspectRatioFit(img.width, img.height, maxWidth, maxHeight);
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext('2d');
  if (ctx) ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', 0.7);
}

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
): {
  width: number;
  height: number;
} {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}
