import React from 'react';
import { VIDEO } from '../../services/assets';
import YMContainer from '../../../components/container/YMContainer';
import GoTo from '../../../components/rightArrow/GoTo.component';
import { route } from '../../services/route';
import { useNavigate } from 'react-router-dom';

function About() {
  const navigate = useNavigate();
  return (
    <div className='fp-about animate__animated animate__fadeIn'>
      <video className='background' muted autoPlay loop>
        <source src={VIDEO.YM_ABOUT_VIDEO} type='video/mp4' />
      </video>

      <div className='content'>
        <YMContainer className='about-container'>
          <div className='body'>
            <div className='mainTitle'>Welcome to Young Minds Imagineers!</div>
            <div className='desc'>
              Our team of talented architects, designers, filmmakers, and game developers is driven by a shared vision:
              to create immersive and captivating experiences that push the boundaries of creativity and technology.
              With our expertise in the industry-leading Unreal Engine, we excel at transforming ideas into
              hyper-realistic visualizations that truly come to life.
            </div>
            <div className='contentBody'>
              <div>
                We are passionate about bringing innovative and sustainable designs to life. We are a dynamic
                architectural firm that goes beyond traditional boundaries, offering a wide range of creative services
                including architectural design, interior design, film making and production, as well as game
                development.
              </div>{' '}
              <div>
                We believe in the power of imagination and innovation. With a strong commitment to sustainability and a
                passion for pushing creative boundaries, we are dedicated to transforming ideas into realities that
                leave a lasting impact. Join us on this extraordinary journey and let your imagination soar.
              </div>
            </div>{' '}
            {/* <div className='contentBody'>
              <div>
                We strive for excellence in applying the talents of our qualified artists, accelerating design processes
                and crafting powerful narratives.
              </div>{' '}
              <div>
                Our team of nearly 100 professionals hails from 24 countries, producing groundbreaking work from our
                headquarters in Budapest and satellite offices in Cluj-Napoca and Verona. Watch this flythrough video
                featuring our HQ!
              </div>
            </div> */}
          </div>
        </YMContainer>
      </div>
    </div>
  );
}

export default About;
