import { Field } from "formik";
import React from "react";

interface InputBox {
  type:
    | "text"
    | "password"
    | "submit"
    | "reset"
    | "radio"
    | "checkbox"
    | "button"
    | "file"
    | "image"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "url"
    | "week"
    | "search"
    | "tel"
    | "text_area";
  name?: string;
  className?: string;
  value?: string;
  label?: string | null;
  id: string;
  placeholder: string;
  style?: any;
  forceUseChange?: boolean;
  onChange?: any;
}
function FormikInputBox({
  style,
  type,
  name,
  className,
  value,
  label = null,
  id,
  placeholder,
  onChange,
  forceUseChange,
}: InputBox) {
  return (
    <div className='ymInputBox formik'>
      {label ? <label htmlFor={id}>{label}</label> : null}
      {type == "text_area" ? (
        forceUseChange ? (
          <Field onChange={onChange} style={style} id={id} name={id} placeholder={placeholder} />
        ) : (
          <Field style={style} id={id} name={id} placeholder={placeholder} />
        )
      ) : forceUseChange ? (
        <Field onChange={onChange} style={style} id={id} name={id} placeholder={placeholder} />
      ) : (
        <Field style={style} id={id} name={id} placeholder={placeholder} />
      )}
    </div>
  );
}

export default FormikInputBox;
