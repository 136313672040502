import axios from "axios";
import React, { useEffect, useState } from "react";
import { deleteProject, getAllProjects } from "../../services/api.service";
import toasterService from "../toast/ToasterService.component";

const URL = "https://jsonplaceholder.typicode.com/users";

const ProjectTable = () => {
  const [data, setData] = useState<any>([]);
  const getData = async () => {
    const response = await getAllProjects();
    console.log(response);
    setData(response);
  };
  const renderHeader = () => {
    let headerElement = ["id", "name", "category", "sub category", "operation"];

    return headerElement.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };

  const ctaDeleteProjectHandler = async (id: any, category: string) => {
    try {
      console.log(`ctaDeleteProjectHandler =>`);
      console.log(`deleting a project ${data.category}`);
      const sure = window.confirm("Are you sure?");
      console.log(sure);
      if (!sure) {
        toasterService.set({
          title: "Empty Field!",
          message: `[Empty Field] Error While deleting a Project`,
          type: "error",
          toastSize: "toastSmall",
        });
        return false;
      }
      const response = await deleteProject(id, category);
      console.log(response);
      if (response) {
        getData();
        toasterService.set({
          title: "Success!",
          message: `Successfully deleted a Project.`,
          type: "success",
          toastSize: "toastSmall",
        });
      } else {
        toasterService.set({
          title: "Failed!",
          message: `Faliure.`,
          type: "error",
          toastSize: "toastSmall",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();

    return () => {};
  }, []);

  return (
    <div className='table'>
      <table id='employee'>
        <thead>
          <tr>{renderHeader()}</tr>
        </thead>
        <tbody>
          {data.length >= 1 &&
            data.map(({ _id, category, subCategory, title }: any, index: number) => {
              console.log(_id);
              return (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{title}</td>
                  <td>{category}</td>
                  <td>{subCategory}</td>
                  <td className='operation'>
                    <button
                      className='button'
                      onClick={() => {
                        ctaDeleteProjectHandler(_id, category);
                      }}>
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTable;
