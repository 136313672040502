import React, { useState } from "react";
import { loginAdmin } from "../../../services/api.service";
import sha256 from "sha256";
import toasterService from "../../../components/toast/ToasterService.component";

function Login({ setisAuth }: any) {
  const [userName, setuserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const login = async () => {
    console.log(userName, password);
    try {
      localStorage.removeItem(`ymAuth`);
      const res: any = await loginAdmin({
        userName,
        password: sha256(password),
      });
      localStorage.setItem("ymAuth", res.jwt);
      setisAuth(true);
    } catch (error) {
      console.log(error);
      toasterService.set({
        title: "Something went wrong!",
        message: `Login Failed`,
        type: "error",
        toastSize: "toastSmall",
      });
    }
  };
  return (
    <div className='loginContainer'>
      <div className='container'>
        <div className='form' id='login'>
          <h1 className='form__title'>Welcome back</h1>
          <div className='form__input-group'>
            <input
              type='text'
              className='form__input'
              autoFocus={true}
              onChange={(e) => {
                setuserName(e.target.value);
              }}
              placeholder='Username'
              id='usernameinput'
            />
          </div>
          <div className='form__input-group'>
            <input
              type='password'
              className='form__input'
              autoFocus={true}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder='Password'
              id='passwordInput'
            />
            <div className='padding'></div>
            <button onClick={() => login()} className='form__button' id='loginButton'>
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
