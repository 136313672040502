import React, { useEffect, useState } from "react";

import {
  CatergoryDropDown,
  SubCatergoryDropDown,
  reactSelectOption,
} from "../../../../components/common/Dropdown.component";
import GoBack from "../../../../components/rightArrow/GoBack.component";
import { route } from "../../../services/route";
import { useNavigate } from "react-router-dom";
import { addNewProject, addNewRealEstate, getCategories } from "../../../../services/api.service";
import { formatText } from "../../../../services/helper.service";
import Loading from "../../../../components/common/Loading.component";
import toasterService from "../../../../components/toast/ToasterService.component";
import InputBox from "../../../../components/common/InputBox.component";
import TMDropZone from "../../../../components/common/YMDropZone.component";
import FormikInputBox from "../../../../components/common/FormikInputBox.component";
import { Formik, Field, Form, FormikHelpers } from "formik";
import TextRichBox from "../../../../components/common/TextRichBox.component";
interface Values {
  title: string;
  description: string;
}
const data = {
  "title": "Real Estate",
  "description": "<p>This is the real Estate</p>",
  "buildingMaterial": "Blender",
  "skyBoxFileName": "sky.hdr",
  "modelGLBFilename": "banepa.glb",
  "maxCameraOrbit": "-60deg 70deg auto",
  "minCameraOrbit": "-150deg -70deg auto",
  "hotspot_0_name": "Detour",
  "hotspot_0_position": "-2.1844835446149444m 2.4206490027657113m -79.9069507417119m",
  "hotspot_0_normal": "-0.4620434011873405m 0.8378208540498548m -0.29081284692806475m",
  "hotspot_1_name": "My House",
  "hotspot_1_position": "4.09257550613975m 7.504018949192633m -68.95049485823378m",
  "hotspot_1_normal": "-0.9977957917532038m -0.024303086988255382m -0.06174882932038876m",
};
function Add3DModels() {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [ImageBlobs, setImageBlobs] = useState([]);
  const [imglist, setImgList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState("");
  useEffect(() => {
    console.log("from Project");
    console.log(formData);
    return () => {};
  }, [formData]);

  const addRealEstate = async (data: any) => {
    console.log(`Adding a project ${data.category}`);
    setisLoading(true);
    const sure = window.confirm("Are you sure?");
    console.log(sure);
    if (!sure) {
      setisLoading(false);
      toasterService.set({
        title: "Empty Field!",
        message: `[Empty Field] Error While Adding a new Project`,
        type: "error",
        toastSize: "toastSmall",
      });
      return false;
    }
    toasterService.set({
      title: "Adding a new Project",
      message: `Pending`,
      type: "warn",
      toastSize: "toastSmall",
    });
    if (data["media"]["images"].length <= 0) {
      setisLoading(false);
      toasterService.set({
        title: "Images is empty!",
        message: `Images is empty`,
        type: "error",
        toastSize: "toastSmall",
      });
      return false;
    }
    // data['media']['thumbnail'] = data['media']['images'][0];
    const res = await addNewRealEstate(data).catch((e) => {
      console.error(e);
      toasterService.set({
        title: "Something went wrong!",
        message: `Error While Adding a new Project`,
        type: "error",
        toastSize: "toastSmall",
      });
      setisLoading(false);
    });
    if (res) {
      console.log(res);
      toasterService.set({
        title: "Success!",
        message: `Successfully added a new project.`,
        type: "success",
        toastSize: "toastSmall",
      });
    }
    setisLoading(false);
  };

  useEffect(() => {
    return () => {};
  }, []);
  const [numberOfHotSpot, setNumberOfHotSpot] = useState<number>(0);

  return (
    <div className='createAProject'>
      <GoBack
        onClick={() => {
          navigate(route.ADMIN);
        }}>
        Back
      </GoBack>
      <h3>Add a new 3D Model</h3>
      {isLoading && <Loading />}
      {!isLoading && (
        <Formik
          enablereinitialize={true}
          initialValues={{
            title: "",
            description: "",
          }}
          onSubmit={(values: any, { setSubmitting }: FormikHelpers<Values>) => {
            values["description"] = descriptionValue;
            console.log("images", ImageBlobs);
            values["media"] = {
              images: ImageBlobs,
              youTube: values.ytLink,
            };
            delete values["ytLink"];
            console.log(values);

            setFormData(values);
            addRealEstate(values);
            // console.log(values);
          }}>
          <Form>
            <FormikInputBox
              id={"title"}
              type='text'
              label={"Title"}
              placeholder={"Model of an Apple pie"}
            />
            {/* <FormikInputBox
            id='description'
            type={'text_area'}
            label={'Description'}
            placeholder={'Castle Black is a formidable stronghold ...'}
          /> */}
            <TextRichBox
              descriptionValue={descriptionValue}
              setDescriptionValue={setDescriptionValue}
              id='description'
              label={"Description"}
            />
            <FormikInputBox
              id='buildingMaterial'
              type={"text"}
              label={"Building Material"}
              placeholder={"Unity, Blender ..."}
            />
            <FormikInputBox
              id='ytLink'
              type={"text"}
              label={"YouTube Link"}
              placeholder={"yt.com/ym"}
            />
            <TMDropZone
              setImgList={setImgList}
              imglist={imglist}
              ImageBlobs={ImageBlobs}
              setImageBlobs={setImageBlobs}
            />
            <hr />
            <h4>Model Settings</h4>
            <FormikInputBox
              id='skyBoxFileName'
              type={"text"}
              label={"SkyBox File Name"}
              placeholder={"mountain.hdr"}
            />{" "}
            <FormikInputBox
              id='modelGLBFilename'
              type={"text"}
              label={"Model GLB Filename"}
              placeholder={"banepa.glb"}
            />
            <FormikInputBox
              id='maxCameraOrbit'
              type={"text"}
              label={"Max Camera Orbit"}
              placeholder={"-60deg 70deg auto"}
            />{" "}
            <FormikInputBox
              id='shadowIntensity'
              type={"text"}
              label={"Shadow intensity"}
              placeholder={"0.38"}
            />{" "}
            <FormikInputBox id='exposure' type={"text"} label={"exposure"} placeholder={"0.66"} />{" "}
            <FormikInputBox
              id='shadowSoftness'
              type={"text"}
              label={"Shadow softness"}
              placeholder={"1"}
            />{" "}
            <FormikInputBox
              id='toneMapping'
              type={"text"}
              label={"Tone mapping"}
              placeholder={"commerce"}
            />{" "}
            <FormikInputBox
              id='minCameraOrbit'
              type={"text"}
              label={"Min Camera Orbit"}
              placeholder={"-150deg -70deg auto"}
            />{" "}
            <FormikInputBox
              id='googleMapHTML'
              type={"text"}
              label={"Google Map HTML"}
              placeholder={"<iframe  src='https: ...."}
            />{" "}
            <FormikInputBox
              id='numberOfHotspot'
              type={"text"}
              label={"Number of hotspot"}
              forceUseChange={true}
              onChange={(e: any) => {
                setNumberOfHotSpot(parseInt(e.target.value));
              }}
              placeholder={"2"}
            />{" "}
            {numberOfHotSpot > 0 ? (
              <>
                {Array.from(Array(numberOfHotSpot), (e, i) => {
                  return (
                    <div className='HotSpotField'>
                      <p className='hs_title'>{`Hotspot#${i + 1}`}</p>
                      <FormikInputBox
                        id={`hotspot_${i}_name`}
                        type={"text"}
                        label={`Hotspot name`}
                        placeholder={`My hotspot`}
                      />
                      <FormikInputBox
                        id={`hotspot_${i}_position`}
                        type={"text"}
                        label={`Data position`}
                        placeholder={`-2.18449444m 2.490027113m -79.90119m`}
                      />{" "}
                      <FormikInputBox
                        id={`hotspot_${i}_normal`}
                        type={"text"}
                        label={`Data normal`}
                        placeholder={`-2.18449444m 2.490027113m -79.90119m`}
                      />
                    </div>
                  );
                })}
              </>
            ) : null}
            <button className='YMformikSumbit' type='submit'>
              Submit
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default Add3DModels;
