import React, { useEffect, useRef } from 'react';
import { AiFillFacebook, AiFillInstagram, AiFillTwitterSquare } from 'react-icons/ai';
function Footer() {
  const refs = useRef<HTMLInputElement>(null);
  useEffect(() => {
    window.addEventListener(
      'resize',
      function (event) {
        refs.current?.style.setProperty('bottom', '0');
        refs.current?.style.setProperty('left', '0');
      },
      true
    );
    return () => {};
  }, []);

  return (
    <div className='ym-footer' ref={refs}>
      <div>© 2023 YM Imagineers. Privacy Policy · Terms & Conditions Office: Kathmandu, Nepal</div>
      <div>ymimagineers@gmail.com +977 984-1201154</div>
      <div className='socialMedia'>
        <AiFillFacebook
          onClick={() => {
            window.open('https://www.facebook.com/ym.imagineers?mibextid=ZbWKwL', '_blank');
          }}
          className='social-media-icon'
        />
        <AiFillInstagram
          onClick={() => {
            window.open('https://instagram.com/ym_imagineers?igshid=MzRlODBiNWFlZA==', '_blank');
          }}
          className='social-media-icon'
        />
        <AiFillTwitterSquare
          onClick={() => {
            window.open('', '_blank');
          }}
          className='social-media-icon'
        />
      </div>
    </div>
  );
}

export default Footer;
