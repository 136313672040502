import React from "react";
import ProjectTable from "../../../components/common/Table";
import GoBack from "../../../components/rightArrow/GoBack.component";
import { useNavigate } from "react-router-dom";
import { route } from "../../services/route";
import RealEstateTable from "../../../components/common/RealEstateTable";
import toasterService from "../../../components/toast/ToasterService.component";

function AdminProjectsTable() {
  const navigate = useNavigate();

  return (
    <div className='projects_table_container'>
      <GoBack
        onClick={() => {
          navigate(route.ADMIN);
        }}>
        Back
      </GoBack>
      <h3>List of projects</h3>
      <ProjectTable />
      <h3>List of RealEstate</h3>
      <RealEstateTable />
    </div>
  );
}

export default AdminProjectsTable;
