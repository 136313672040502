import React from 'react';
import GoTo from '../rightArrow/GoTo.component';
import { useNavigate } from 'react-router-dom';
import { route } from '../../core/services/route';

function GeneralFooter({ className, onClick }: any) {
  const navigate = useNavigate();
  return (
    <div className={`general-info-footer ${className}`}>
      <div className='general pod p1'>
        <hr className='upperLine' />
        <div className='title'>General questions</div>
        <div className='desc'>If you have a question or comment, please reach out:</div>
        <GoTo className='link'>ymimagineers@gmail.com</GoTo>
      </div>{' '}
      <div className='general pod p2'>
        <hr className='upperLine' />
        <div className='title'>Press inquiries</div>
        <div className='desc'>For media inquiries and interview requests, please contact:</div>
        <GoTo className='link'>ymimagineers@gmail.com</GoTo>
      </div>{' '}
      <div className='general pod p3'>
        <hr className='upperLine' />
        <div className='title'>Join the team</div>
        <div className='desc'>We welcome talents from all around the world.</div>
        <GoTo className='link'>ymimagineers@gmail.com</GoTo>
      </div>
      <div className='project'>
        <hr className='upperLine' />
        <div className='title'>Ready to discuss your next project?</div>
        <div className='desc'>
          Preparing the groundwork for a rewarding business relationship and a successful project begins here. Tell us
          about your unique project by answering a couple of questions briefly.
        </div>
        <GoTo className='contactBody fillForm' onClick={onClick}>
          <div>Fill out form</div>
        </GoTo>
      </div>
    </div>
  );
}

export default GeneralFooter;
