import React from 'react';
import Select from 'react-select';
export type reactSelectOption = Array<{ value: string; label: string }>;
interface InputBox {
  name?: string;
  className?: string;
  value?: string;
  label?: string | null;
  options: reactSelectOption;
  onChange: any;
}
function DropDown({ name, className, onChange, value, label = null, options }: InputBox) {
  return (
    <div className='ymDropdown'>
      {label ? <div>{label}</div> : null}
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            color: 'white',
            background: '#121212',
          }),
        }}
        onChange={onChange}
        options={options}
      />
    </div>
  );
}

export function TeamPositionSelect({ className, onChange, value, label = null, options }: any) {
  return (
    <div className='ymDropdown'>
      {label ? <div>{label}</div> : null}
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            color: 'white',
            background: '#121212',
          }),
        }}
        isMulti
        name='colors'
        onChange={onChange}
        options={options}
      />
    </div>
  );
}

export function SubCatergoryDropDown({ selectedSubCategory, className, onChange, value, label = null, options }: any) {
  return (
    <div className='ymDropdown'>
      {label ? <div>{label}</div> : null}
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            color: 'white',
            background: '#121212',
          }),
        }}
        // value={selectedSubCategory || ''}
        onChange={onChange}
        options={options}
      />
    </div>
  );
}
export function CatergoryDropDown({ selectedCategory, className, onChange, value, label = null, options }: any) {
  return (
    <div className='ymDropdown'>
      {label ? <div>{label}</div> : null}
      <Select
        className='react-select-container'
        classNamePrefix='react-select'
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            color: 'white',
            background: '#121212',
          }),
        }}
        onChange={onChange}
        options={options}
      />
    </div>
  );
}
