import React, { useState } from 'react';
import { VIDEO } from '../../services/assets';
import YMContainer from '../../../components/container/YMContainer';
import GoTo from '../../../components/rightArrow/GoTo.component';
import { route } from '../../services/route';
import { useNavigate } from 'react-router-dom';

const SERVICE_ITEMS: Record<string, string> = {
  'Interior Design':
    'Our interior design services are tailored to transform spaces into personalized and captivating environments. Whether its a residential or commercial project, we blend aesthetics, functionality, and the unique preferences of our clients to create interiors that are both visually striking and comfortable.',
  '3D Visualization':
    'Our expertise in utilizing Unreal Engine allows us to create hyper-realistic 3D visualizations of architectural designs, interiors, and virtual environments. With attention to detail and a focus on creating immersive experiences, we help clients visualize their projects before they become a reality.',
  'Game Development':
    'Immerse yourself in captivating virtual worlds with our game development services. We specialize in creating interactive and visually stunning games for various platforms. From concept design to programming and game mechanics, our talented team delivers engaging gaming experiences.',
  'Architectural Design':
    'Our team of skilled architects specializes in creating innovative and sustainable designs for a variety of projects. From concept development to construction documentation, we bring our expertise to every stage of the architectural process, ensuring functional and visually stunning results.',
  'Digital Marketing Solutions':
    'Enhance your online presence and reach your target audience with our comprehensive digital marketing solutions. From social media management and content creation to search engine optimization (SEO) and targeted advertising, our digital marketing experts will help you establish a strong online presence and achieve your business goals.',
  'Film Making and Production':
    'With our cutting-edge technology and storytelling prowess, we bring your ideas to life on the big screen. Our experienced team of filmmakers, visual effects artists, and sound designers collaborates to create cinematic masterpieces that leave a lasting impact on audiences.',
  'Historical Artifact Conservation':
    'Enhance your online presence and reach your target audience with our comprehensive digital marketing solutions. From social media management and content creation to search engine optimization (SEO) and targeted advertising, our digital marketing experts will help you establish a strong online presence and achieve your business goals.',
};

function Service() {
  const [serviceItemName, setServiceItemName] = useState<string>('Architectural Visualization');
  const navigate = useNavigate();
  return (
    <div className='fp-service animate__animated animate__fadeIn'>
      <video className='background' muted autoPlay loop>
        <source src={VIDEO.YM_SERVICE_VIDEO} type='video/mp4' />
      </video>

      <div className='content'>
        <YMContainer className='service-container'>
          <div className='body'>
            <div className='mainTitle'>Our expertise</div>
            <div className='desc'>
              At Young Minds Imagineers, we blend creativity, technology, and expertise to deliver outstanding results.
              Our diverse range of services allows us to bring your vision to life, whether it's in the realms of
              architecture, film, gaming, conservation, or digital marketing.
            </div>
            <div className='contentBody'>
              <div className='serviceItems'>
                <ul>
                  {Object.keys(SERVICE_ITEMS).map((itemName, index) => {
                    return (
                      <li
                        className={`${serviceItemName == itemName ? 'selected' : ''}`}
                        onClick={() => {
                          setServiceItemName(itemName);
                        }}
                        key={index}
                      >
                        <GoTo showInitArrow={serviceItemName == itemName ? true : false}>{itemName}</GoTo>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className='serviceDesc animate__animated animate__fadeIn'>
                {SERVICE_ITEMS[serviceItemName as string]}
              </div>
            </div>{' '}
            <div className='contentBody_mobile'>
              {Object.keys(SERVICE_ITEMS).map((itemName, index) => {
                return (
                  <div key={index}>
                    <div className='cm_title'>{itemName}</div>
                    <div className='cm_body'>{SERVICE_ITEMS[itemName]}</div>
                  </div>
                );
              })}
            </div>{' '}
          </div>
        </YMContainer>
      </div>
    </div>
  );
}

export default Service;
