import React, { useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import YMContainer from '../../../components/container/YMContainer';
import { WORK_THUMBNAILS } from '../../services/assets';
import WFM from '../../../components/workFilterMenu/WFM.component';
import { getFilteredData } from '../../../services/api.service';
import { useNavigate } from 'react-router-dom';
import { route } from '../../services/route';
// const images: Array<{ src: string }> = [];

function Work() {
  const [filteredData, setFilterData] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('architecture');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const navigate = useNavigate();
  const filter = async (data: { selectedCategory: string; selectedSubCategory: string }) => {
    console.log(data);
    console.log('Filtering data=>>');
    const res = await getFilteredData(data).catch((e) => {
      console.log(e);
    });
    console.log(res);
    if (res) setFilterData(res);
  };
  useEffect(() => {
    filter({
      selectedCategory,
      selectedSubCategory,
    });
    return () => {};
  }, []);
  const [filterMobile, setfilterMobile] = useState(false);
  return (
    <YMContainer className='work-container'>
      <WFM
        setfilterMobile={setfilterMobile}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        setFilterData={setFilterData}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        selectedSubCategory={selectedSubCategory}
        filter={filter}
        className={`work-filter ${filterMobile ? 'show' : 'hide'}`}
      />
      <button
        className='showFilter'
        onClick={() => {
          setfilterMobile(!filterMobile);
        }}>
        Show Filter
      </button>
      <div className='productContainer'>
        {filteredData
          ? filteredData.map((data: any) => {
              return (
                <div
                  className='productItem'
                  onClick={() => {
                    navigate(`/project/${data.category}/${data._id}`);
                  }}>
                  {data?.media?.images.length >= 1 ? (
                    <img src={data?.media?.images[0]} />
                  ) : (
                    <img src={WORK_THUMBNAILS['work_1']} />
                  )}
                  <div className='projectcard'>
                    <div className='projectcard__title'>{data?.title}</div>
                    <p className='projectcard__quickinfo'>
                      {data?.description.length >= 10 ? data?.description : `${data?.description.slice(0, 10)}...`}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </YMContainer>
  );
}

export default Work;
