import React, { useEffect } from 'react';
import YMContainer from '../container/YMContainer';
import GoTo from '../rightArrow/GoTo.component';
import { useNavigate } from 'react-router-dom';
import { route } from '../../core/services/route';
import GeneralFooter from '../footer/GeneralFooter.component';
interface Menu {
  isOpen: boolean;
  setClosedMenu: any;
  setMenuOn: any;
}
const item = {};
function Menu({ isOpen, setClosedMenu, setMenuOn }: Menu) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`isMenuOpen: ${isOpen}`);
    if (isOpen == false) {
      setTimeout(() => {
        setClosedMenu((res: any) => {
          return false;
        });
      }, 2000);
    }
    return () => {};
  }, [isOpen]);

  return (
    <div className={`ym-header-menu animate__animated ${!isOpen ? 'animate__fadeOut' : 'animate__fadeIn'}`}>
      <YMContainer className='box'>
        <ul className='item-plate'>
          <li
            className='item animate__animated animate__slideInDown'
            onClick={() => {
              setMenuOn(false);
              navigate(route.WORKS);
            }}>
            <GoTo showInitArrow={false}>Works</GoTo>
          </li>
          <li
            className='item animate__animated animate__slideInDown'
            onClick={() => {
              setMenuOn(false);
              navigate(route.SERVICE);
            }}>
            <GoTo showInitArrow={false}>Services</GoTo>
          </li>
          <li
            className='item animate__animated animate__slideInDown'
            onClick={() => {
              setMenuOn(false);
              navigate(route.CAREERS);
            }}>
            <GoTo showInitArrow={false}>Careers</GoTo>
          </li>
          <li
            className='item animate__animated animate__slideInDown'
            onClick={() => {
              setMenuOn(false);
              navigate(route.ABOUT);
            }}>
            <GoTo showInitArrow={false}>About us</GoTo>
          </li>
          <li
            className='item animate__animated animate__slideInDown'
            onClick={() => {
              setMenuOn(false);
              navigate(route.CONTACT);
            }}>
            <GoTo showInitArrow={false}>Contacts</GoTo>
          </li>
        </ul>
        <GeneralFooter
          onClick={() => {
            setMenuOn(false);
            navigate(route.REQUEST_A_PROPOSAL);
          }}
        />
      </YMContainer>
    </div>
  );
}

export default Menu;
