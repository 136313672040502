export const API = {
  addSubCategory: "work/addSubCategory",
  deleteProject: "work/deleteProject",
  deleteRealEsate: "work/deleteRealEsate",
  addProject: "work/addAProject",
  getAllProject: "work/getAllProject",
  addRealEstate: "work/addRealEstate",
  getProjectDetails: "work/getProjectDetails",
  filterData: `work/filterProject`,
  getAllRealEstate: "work/getAllRealEstate",
  getCategories: `work/getCategories`,
  loginAdmin: "work/login",
  getRealEstateDetails: "work/getRealEstateDetails",
};
