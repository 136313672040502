import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { reSizeImage } from "../../services/helper.service";

function DragDropFile({ setImgList, imglist, ImageBlobs, setImageBlobs }: any) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleFile = async (files: any) => {
    console.log(files);
    setImgList(null);
    const imglist_ref = imglist || [];
    const image_blobs = ImageBlobs || [];
    console.log(`image_blobs's length = ${image_blobs.length}`);
    const isThumbnail = image_blobs.length == 0;
    let liss: Array<string> = [];
    let bufferListTemp: Array<any> = [];
    imglist_ref.map((link: string) => {
      liss.push(link);
    });
    image_blobs.map((data: any) => {
      bufferListTemp.push(data);
    });
    for (let i = 0; i < files.length; i++) {
      console.log(`looping throught files (${i}/${files.length})`);
      console.log(`file: ${files[i]}`);
      console.log(`isThumbnail: ${isThumbnail}`);
      if (i >= 1) {
        const res = await fileToBuffer(files[i]).catch((e) => {
          console.error(e);
        });
        liss.push(URL.createObjectURL(files[i]));
        console.log(res);
        if (res) bufferListTemp.push(res);
      } else {
        console.log("compressing for thumbnail");
        const resCompressed = await fileToBufferCompressed(files[i]).catch((e) => {
          console.error(e);
        });
        if (resCompressed) bufferListTemp.push(resCompressed);
        console.log("storing for images");
        const res = await fileToBuffer(files[i]).catch((e) => {
          console.error(e);
        });
        liss.push(URL.createObjectURL(files[i]));
        if (res) bufferListTemp.push(res);
      }
    }
    console.log("bufferListTOadd");
    console.log(bufferListTemp);
    setImageBlobs((p: any) => {
      return bufferListTemp;
    });
    setImgList((p: any) => {
      return liss;
    });
  };
  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  function fileToBuffer(file: File): Promise<{
    image: any;
    mime: any;
  }> {
    return new Promise((resolve, reject) => {
      var fileImage = file;
      console.log(fileImage, fileImage.type);
      let reader = new FileReader();
      reader.readAsDataURL(fileImage);
      reader.onload = function (event: any) {
        if (reader.readyState === FileReader.DONE) {
          const base64String = reader.result as string;
          const base = "base64" + base64String.split("base64")[1];
          resolve({
            image: base,
            mime: fileImage.type,
          });
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
  function fileToBufferCompressed(file: File): Promise<{
    image: any;
    mime: any;
  }> {
    return new Promise((resolve, reject) => {
      var fileImage = file;
      console.log(fileImage, fileImage.type);
      let reader = new FileReader();
      reader.readAsDataURL(fileImage);
      reader.onload = function (event: any) {
        console.log("reader done!");
        if (reader.readyState === FileReader.DONE) {
          var blobURL = URL.createObjectURL(file);
          console.log(blobURL);
          var image = new Image();
          image.src = blobURL;
          image.onload = function () {
            console.log("image also done!");
            var resized = reSizeImage(image, 400, 500);
            resolve({
              image: resized.slice(16).toString(),
              mime: fileImage.type,
            });
          };
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    console.log(e.target.files);
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef) if (inputRef.current) inputRef.current.click();
  };

  return (
    <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input
        ref={inputRef}
        type='file'
        id='input-file-upload'
        multiple={true}
        onChange={handleChange}
      />
      <label
        id='label-file-upload'
        htmlFor='input-file-upload'
        className={dragActive ? "drag-active" : ""}>
        <div>
          <p>Drag and drop your images or click on it to upload</p>
          {/* <button className='upload-button' onClick={onButtonClick}>
            Upload a file
          </button> */}
        </div>
      </label>
      {dragActive && (
        <div
          id='drag-file-element'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}></div>
      )}
    </form>
  );
}
function TMDropZone({ imglist, setImgList, ImageBlobs, setImageBlobs }: any) {
  // const [imglist, setImgList] = useState<Array<string> | null>(null);
  useEffect(() => {
    console.log("from imglist useEfect ");
    console.log(imglist);
    return () => {};
  }, [imglist]);
  return (
    <div className='dragDropZone'>
      <DragDropFile
        imglist={imglist}
        setImgList={setImgList}
        ImageBlobs={ImageBlobs}
        setImageBlobs={setImageBlobs}
      />
      <div className='droppedImages'>
        {imglist
          ? imglist.map((src: string, index: number) => {
              return <img key={`i_${index}`} src={src} />;
            })
          : null}
      </div>
    </div>
  );
}

export default TMDropZone;
