import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { YM_LOGO } from '../../core/services/assets';
import Menu from '../menu/Menu.component';
import { useNavigate } from 'react-router-dom';
import { route } from '../../core/services/route';
function Header() {
  const [menuOn, setMenuOn] = useState(false);
  const navigate = useNavigate();
  const [isClosedMenu, setClosedMenu] = useState(false);
  return (
    <div className='header-container'>
      <div className='ym-header'>
        <img
          className='ym-logo'
          src={YM_LOGO}
          onClick={() => {
            navigate(route.HOME);
          }}
        />
        {menuOn ? (
          <AiOutlineCloseSquare
            className='menu'
            size={30}
            onClick={() => {
              setMenuOn(false);
            }}
          />
        ) : (
          <FiMenu
            className='menu'
            size={22}
            onClick={() => {
              setMenuOn(true);
              setClosedMenu(true);
            }}
          />
        )}
      </div>
      {isClosedMenu && <Menu setMenuOn={setMenuOn} isOpen={menuOn} setClosedMenu={setClosedMenu} />}
    </div>
  );
}

export default Header;
