import React from "react";
import InputBox from "../../../components/common/InputBox.component";
import GoTo from "../../../components/rightArrow/GoTo.component";
import YMButton from "../../../components/common/YMButton.component";
import Project from "./addAProject/Project.admin";
import AddSubCategory from "./subCategory/AddSubCategory.component";
import Loading from "../../../components/common/Loading.component";
import { useNavigate } from "react-router-dom";
import { route } from "../../services/route";

function Admin() {
  const navigate = useNavigate();

  return (
    <div className='admin'>
      <div>
        <GoTo
          onClick={() => {
            navigate(route.ADMIN_ADD_PROJECT);
          }}
          className={"adminItem"}>
          Add Projects
        </GoTo>
        <GoTo
          onClick={() => {
            navigate(route.ADMIN_ADD_SUB_CATEGORY);
          }}
          className={"adminItem"}>
          Add Sub-Category
        </GoTo>
        <GoTo
          onClick={() => {
            navigate(route.ADMIN_ADD_MODEL);
          }}
          className={"adminItem"}>
          Add 3D Model
        </GoTo>
        <GoTo
          onClick={() => {
            navigate(route.VIEW_PROJECT);
          }}
          className={"adminItem"}>
          View Projects
        </GoTo>
        {/* <Project /> */}
        {/* <AddSubCategory /> */}
        {/* <InputBox type={'password'} className='adminInput' label={'Admin'} />
        <YMButton>Login</YMButton> */}
      </div>
    </div>
  );
}

export default Admin;
