import axios from "axios";
import { API } from "./apiRoutes";
import {
  IArchitecture,
  IInteriorDesign,
  IVirtualProduction,
  TProjectCategory,
} from "../interfaces/project.interface";
import { formatText } from "./helper.service";
let api = axios.create({ baseURL: process.env.REACT_APP_EXPRESS_URL });
// let api = axios.create({ baseURL: `https://ym-imagineers.udojmpoq8jtae.ap-south-1.cs.amazonlightsail.com/` });
interface IsubCategoryAPIBody {
  category: TProjectCategory | "";
  newSubCategory: string;
}

export async function deleteProject(id: string, category: string): Promise<boolean> {
  return new Promise(async (res, rej) => {
    console.log("fetching getProjectDetails ", id);
    const response = await api.delete(`${API.deleteProject}?id=${id}&category=${category}`, {
      headers: {
        "Authorization": localStorage.getItem("ymAuth")
          ? localStorage.getItem("ymAuth")
          : undefined,
      },
    });
    console.log(response);
    if (response.status == 200) {
      res(true);
    } else {
      console.error(getFilteredData);
      rej(false);
    }
  });
}
export async function deleteRealEstate(id: string): Promise<boolean> {
  return new Promise(async (res, rej) => {
    console.log("fetching deleteRealEstate ", id);
    const response = await api.delete(`${API.deleteRealEsate}?id=${id}`, {
      headers: {
        "Authorization": localStorage.getItem("ymAuth")
          ? localStorage.getItem("ymAuth")
          : undefined,
      },
    });
    console.log(response);
    if (response.status == 200) {
      res(true);
    } else {
      console.error(getFilteredData);
      rej(false);
    }
  });
}
export async function getProjectDetails(
  id: string,
  category: string
): Promise<IArchitecture | IInteriorDesign | IVirtualProduction> {
  return new Promise(async (res, rej) => {
    console.log("fetching getProjectDetails ", id);
    const getFilteredData = await api.get(`${API.getProjectDetails}?id=${id}&category=${category}`);
    if (getFilteredData.status == 200) {
      res(getFilteredData.data.data);
    } else {
      console.error(getFilteredData);
      rej(`API FAILED`);
    }
  });
}
export async function getRealEstateDetails(id: string): Promise<any> {
  return new Promise(async (res, rej) => {
    console.log("fetching getRealEstateDetails ", id);
    const getFilteredData = await api.get(`${API.getRealEstateDetails}?id=${id}`);
    if (getFilteredData.status == 200) {
      res(getFilteredData.data.data);
    } else {
      console.error(getFilteredData);
      rej(`API FAILED`);
    }
  });
}
export async function getAllRealEstate(): Promise<any> {
  return new Promise(async (res, rej) => {
    console.log("fetching getAllRealEstate ");
    const getFilteredData = await api.get(`${API.getAllRealEstate}`);
    if (getFilteredData.status == 200) {
      res(getFilteredData.data.data);
    } else {
      console.error(getFilteredData);
      rej(`API FAILED`);
    }
  });
}
export async function getAllProjects(): Promise<any> {
  return new Promise(async (res, rej) => {
    console.log("fetching getAllProject ");
    const getFilteredData = await api.get(`${API.getAllProject}`);
    if (getFilteredData.status == 200) {
      res(getFilteredData.data.data);
    } else {
      console.error(getFilteredData);
      rej(`API FAILED`);
    }
  });
}
export async function getFilteredData({ selectedCategory, selectedSubCategory }: any) {
  return new Promise(async (res, rej) => {
    console.log("fetching getFilteredData ", { selectedCategory, selectedSubCategory });
    const getFilteredData = await api.get(
      `${API.filterData}?category=${selectedCategory?.toLowerCase()}${
        selectedSubCategory?.length >= 1 ? `&subCategory=${selectedSubCategory?.toLowerCase()}` : ""
      }`
    );
    if (getFilteredData.status == 200) {
      res(getFilteredData.data.data);
    } else {
      console.error(getFilteredData);
      rej(`API FAILED`);
    }
  });
}

export async function loginAdmin(body: { userName: string; password: string }) {
  return new Promise(async (res, rej) => {
    try {
      const addSubCategoryResponse = await api.post(API.loginAdmin, body);
      if (addSubCategoryResponse.status == 200) {
        res(addSubCategoryResponse.data);
      } else {
        console.error(addSubCategoryResponse);
        rej(`API FAILED`);
      }
    } catch (error) {
      rej(`API FAILED`);
    }
  });
}
export async function addNewSubCategory(body: IsubCategoryAPIBody) {
  return new Promise(async (res, rej) => {
    const addSubCategoryResponse = await api.post(API.addSubCategory, body, {
      headers: {
        "Authorization": localStorage.getItem("ymAuth")
          ? localStorage.getItem("ymAuth")
          : undefined,
      },
    });
    if (addSubCategoryResponse.status == 200) {
      res(addSubCategoryResponse.data);
    } else {
      console.error(addSubCategoryResponse);
      rej(`API FAILED`);
    }
  });
}
export async function addNewProject(body: any) {
  return new Promise(async (res, rej) => {
    const addSubCategoryResponse = await api.post(API.addProject, body, {
      headers: {
        "Authorization": localStorage.getItem("ymAuth")
          ? localStorage.getItem("ymAuth")
          : undefined,
      },
    });
    if (addSubCategoryResponse.status == 200) {
      res(addSubCategoryResponse.data);
    } else {
      console.error(addSubCategoryResponse);
      rej(`API FAILED`);
    }
  });
}

export async function addNewRealEstate(body: any) {
  return new Promise(async (res, rej) => {
    const addSubCategoryResponse = await api.post(API.addRealEstate, body, {
      headers: {
        "Authorization": localStorage.getItem("ymAuth")
          ? localStorage.getItem("ymAuth")
          : undefined,
      },
    });
    if (addSubCategoryResponse.status == 200) {
      res(addSubCategoryResponse.data);
    } else {
      console.error(addSubCategoryResponse);
      rej(`API FAILED`);
    }
  });
}
export async function getCategories(): Promise<{ message: string; data: any }> {
  return new Promise(async (res, rej) => {
    console.log("fetching getCategories");
    const apiRes = await api.get(API.getCategories);
    if (apiRes.status == 200) {
      console.log("fetching getCategories [200] success");
      console.log(apiRes.data);
      let processedData: any = {};
      if (apiRes.data.data) {
        Object.keys(apiRes.data.data).map((l) => {
          if (l != "id" && l != "_id" && l != "__v") {
            processedData[l] = null;
            processedData[l] = apiRes.data.data[l];
          }
        });
        console.log(processedData);
        res(processedData);
      } else {
        rej("Empty");
      }
    } else {
      console.error(apiRes);
      rej(`API FAILED`);
    }
  });
}
