import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import YMContainer from "../../../components/container/YMContainer";
import YouTube, { YouTubeProps } from "react-youtube";
import Footer from "../../../components/footer/Footer.component";
import GeneralFooter from "../../../components/footer/GeneralFooter.component";
import { getProjectDetails, getRealEstateDetails } from "../../../services/api.service";
import {
  IArchitecture,
  IInteriorDesign,
  IVirtualProduction,
} from "../../../interfaces/project.interface";
import { formatText } from "../../../services/helper.service";
import ImageGallery from "react-image-gallery";
import parse from "html-react-parser";
import ModelViewer from "../../../components/common/Bike";
import YMButton from "../../../components/common/YMButton.component";
import { route } from "../../services/route";

const img_url =
  "https://ym-imagineers.s3.ap-south-1.amazonaws.com/c62c383a-ac49-4eb9-824d-990d9a982363.png";

function RealEstatePage() {
  const { projectId, category } = useParams();
  const [images, setImages] = useState<Array<{ original: string; thumbnail: string }>>([]);
  const [heroImage, setHeroImage] = useState<any>("");
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState<any | null>(null);
  useEffect(() => {
    console.log(projectId);
    if (projectId) setProjectDetails(projectId);
    return () => {};
  }, []);

  const setProjectDetails = async (id: string) => {
    try {
      const response: any = await getRealEstateDetails(id);
      setProjectData(response);
      if (response.media?.images?.length) {
        let im: Array<{ original: string; thumbnail: string }> = [];
        response.media?.images?.map((url: any, index: any) => {
          if (index == 1) {
            setHeroImage(url);
          }
          if (index > 0)
            im.push({
              original: url as string,
              thumbnail: url as string,
            });
        });
        if (response.media?.images?.length == 1) {
          setHeroImage(response.media?.images[0]);
        }
        setImages(im);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "650px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <div className='projectProfile__container'>
      <div
        className='projectProfile__heroSection'
        style={{
          backgroundImage: `linear-gradient(to top, rgb(0, 0, 0) 2%, transparent 60%), linear-gradient(rgb(0, 0, 0) 2%, transparent 30%), url("${heroImage}")`,
        }}></div>
      <YMContainer className='projectProfile__details'>
        <div className='profileProject_head'>
          <h2 className='__title'>{projectData?.title}</h2>
          {projectData?.description ? (
            <p className='__description'>{parse(projectData?.description)}</p>
          ) : null}

          {projectData && <ModelViewer projectData={projectData} />}
          <div className='real-estate-btn'>
            <button
              onClick={() => {
                window.open(`${window.location.origin}/viewer/${projectData._id}`, "_blank");
              }}>
              View Full Screen
            </button>
          </div>
          {projectData?.googleMapHTML && projectData?.googleMapHTML.length >= 10 ? (
            <div>
              <h2>Google Map Direction:</h2>
              <div dangerouslySetInnerHTML={{ __html: projectData?.googleMapHTML }} />
            </div>
          ) : null}
          {projectData?.media?.youTube?.length >= 1 ? (
            <>
              <h2>Video Reference:</h2>
              <YouTube
                className='projectProfile_ytVideo'
                videoId={projectData?.media?.youTube}
                opts={opts}
                onReady={onPlayerReady}
              />
            </>
          ) : null}
          <h2 className='__title'>Images</h2>
          {images.length >= 1 ? <ImageGallery items={images} /> : null}
        </div>
      </YMContainer>
    </div>
  );
}

export default RealEstatePage;
