import React, { useEffect, useState } from "react";
import InputBox from "../../../../components/common/InputBox.component";
import TMDropZone from "../../../../components/common/YMDropZone.component";
import FormikInputBox from "../../../../components/common/FormikInputBox.component";
import { Formik, Field, Form, FormikHelpers } from "formik";
import TextRichBox from "../../../../components/common/TextRichBox.component";

interface Values {
  title: string;
  description: string;
}
function ArtAndGameDevForm({ setFormData }: any) {
  const [ImageBlobs, setImageBlobs] = useState([]);
  const [imglist, setImgList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState("");
  useEffect(() => {
    console.log(descriptionValue);

    return () => {};
  }, [descriptionValue]);

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
      }}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<Values>) => {
        if (descriptionValue.length <= 1) {
          window.alert("empty description");
          return;
        }
        console.log("images", ImageBlobs);
        values["media"] = {
          images: ImageBlobs,
          youTube: values.ytLink,
        };
        values["description"] = descriptionValue;
        delete values["ytLink"];
        console.log(values);
        setFormData(values);
        console.log(values);
      }}>
      <Form>
        <FormikInputBox id={"title"} type='text' label={"Title"} placeholder={"Castle Black"} />
        <TextRichBox
          descriptionValue={descriptionValue}
          setDescriptionValue={setDescriptionValue}
          id='description'
          label={"Description"}
        />
        <FormikInputBox
          id='ytLink'
          type={"text"}
          label={"YouTube Link"}
          placeholder={"yt.com/ym"}
        />
        <TMDropZone
          setImgList={setImgList}
          imglist={imglist}
          ImageBlobs={ImageBlobs}
          setImageBlobs={setImageBlobs}
        />
        <button className='YMformikSumbit' type='submit'>
          Submit
        </button>
      </Form>
    </Formik>
  );
}

export default ArtAndGameDevForm;
