export const route = {
  HOME: "/home",
  CONTACT: "/contact",
  REAL_ESTATE: "/real-estate",
  ABOUT: "/about",
  SERVICE: "/service",
  WORKS: "/works",
  PROJECT: "/project/:category/:projectId",
  LOGIN: "/login",
  REAL_STATE_PAGE: "/real-estate/:projectId",
  modelViewer: "/viewer/:projectId",
  CAREERS: "/careers",
  REQUEST_A_PROPOSAL: "/request-a-proposal",
  ADMIN: "/d31gzU/admin",
  ADMIN_ADD_SUB_CATEGORY: "/d31gzU/admin/ascg",
  ADMIN_ADD_PROJECT: "/d31gzU/admin/ap",
  ADMIN_ADD_MODEL: "/d31gzU/admin/3d",
  VIEW_PROJECT: "/d31gzU/admin/projects",
};
