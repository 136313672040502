import React, { useEffect, useState } from "react";
import InputBox from "../../../../components/common/InputBox.component";
import TMDropZone from "../../../../components/common/YMDropZone.component";
import FormikInputBox from "../../../../components/common/FormikInputBox.component";
import { Formik, Field, Form, FormikHelpers } from "formik";
import TextRichBox from "../../../../components/common/TextRichBox.component";

interface Values {
  title: string;
  description: string;
}
function ArchitectureForm({ setFormData }: any) {
  const [ImageBlobs, setImageBlobs] = useState([]);
  const [imglist, setImgList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState("");
  useEffect(() => {
    console.log(descriptionValue);

    return () => {};
  }, [descriptionValue]);

  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
      }}
      onSubmit={(values: any, { setSubmitting }: FormikHelpers<Values>) => {
        if (descriptionValue.length <= 1) {
          window.alert("empty description");
          return;
        }
        console.log("images", ImageBlobs);
        values["media"] = {
          images: ImageBlobs,
          youTube: values.ytLink,
        };
        values["rooms"] = {
          bedrooms: values.bedrooms,
          hall: values.hall,
          kitchen: values.kitchen,
        };
        values["description"] = descriptionValue;
        delete values["bedrooms"];
        delete values["ytLink"];
        delete values["hall"];
        delete values["kitchen"];
        console.log(values);
        setFormData(values);
        console.log(values);
      }}>
      <Form>
        <FormikInputBox id={"title"} type='text' label={"Title"} placeholder={"Castle Black"} />
        {/* <FormikInputBox
          id='description'
          type={'text_area'}
          label={'Description'}
          placeholder={'Castle Black is a formidable stronghold ...'}
        /> */}
        <TextRichBox
          descriptionValue={descriptionValue}
          setDescriptionValue={setDescriptionValue}
          id='description'
          label={"Description"}
        />
        <FormikInputBox
          id='responsiblePerson'
          type={"text"}
          label={"Principal Architect"}
          placeholder={"John Snow"}
        />{" "}
        <FormikInputBox
          id='location'
          type={"text"}
          label={"Location"}
          placeholder={"Western end of the Wall"}
        />
        <FormikInputBox
          id='buildingMaterial'
          type={"text"}
          label={"Building Material"}
          placeholder={"Rocks, Woods, .. , Sand"}
        />
        <FormikInputBox id='plot' type={"text"} label={"Plot"} placeholder={"2x3 meters"} />
        <FormikInputBox id='bedrooms' type={"number"} label={"Bedrooms"} placeholder={"20"} />
        <FormikInputBox id='hall' type={"number"} label={"Hall"} placeholder={"20"} />
        <FormikInputBox id='kitchen' type={"number"} label={"Kitchen"} placeholder={"20"} />
        <FormikInputBox
          id='ytLink'
          type={"text"}
          label={"YouTube Link"}
          placeholder={"yt.com/ym"}
        />
        <TMDropZone
          setImgList={setImgList}
          imglist={imglist}
          ImageBlobs={ImageBlobs}
          setImageBlobs={setImageBlobs}
        />
        <button className='YMformikSumbit' type='submit'>
          Submit
        </button>
      </Form>
    </Formik>
  );
}

export default ArchitectureForm;
