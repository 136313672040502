import React, { useEffect, useState } from "react";

import {
  CatergoryDropDown,
  reactSelectOption,
} from "../../../../components/common/Dropdown.component";
import FormikInputBox from "../../../../components/common/FormikInputBox.component";
import { Formik, Field, Form, FormikHelpers } from "formik";
import InputBox from "../../../../components/common/InputBox.component";
import { addNewSubCategory } from "../../../../services/api.service";
import { TProjectCategory } from "../../../../interfaces/project.interface";
import Loading from "../../../../components/common/Loading.component";
import toasterService from "../../../../components/toast/ToasterService.component";
import GoTo from "../../../../components/rightArrow/GoTo.component";
import GoBack from "../../../../components/rightArrow/GoBack.component";
import { useNavigate } from "react-router-dom";
import { route } from "../../../services/route";

const categories: Record<TProjectCategory, Array<string>> = {
  architecture: ["Mud House", "Newari House"],
  "visuals and production": ["virtual_production1", "virtual_production2"],
  "interior design": ["interior_design1", "interior_design2", "interior_design3"],
  "arts and game dev": [],
};
interface Values {
  subCategory: string;
}
function AddSubCategory() {
  const navigate = useNavigate();

  const [category, setCategory] = useState<reactSelectOption | null>(null);
  const [subCategory, setSubCategory] = useState<reactSelectOption | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<TProjectCategory | "">("");
  const [isSelectedSubCategory, setIsSubSelectedCategory] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    let s_c: reactSelectOption = [];
    // setSubSelectedCategory('');
    Object.keys(categories).map((data: string) => {
      if (selectedCategory.replaceAll(" ", "_") == data)
        categories[data as TProjectCategory].map((subData: string) => {
          s_c.push({
            value: subData.replaceAll("_", " "),
            label: subData.replaceAll("_", " "),
          });
        });
    });
    setSubCategory(s_c);
    return () => {};
  }, [selectedCategory]);

  useEffect(() => {
    let c: reactSelectOption = [];
    let s_c: reactSelectOption = [];
    Object.keys(categories).map((data: string) => {
      c.push({
        value: data.replaceAll("_", " "),
        label: data.replaceAll("_", " "),
      });
    });
    setSubCategory(s_c);
    setCategory(c);
    return () => {};
  }, []);

  return (
    <div className='createAProject'>
      <GoBack
        onClick={() => {
          navigate(route.ADMIN);
        }}>
        Back
      </GoBack>
      <h3>Add a new sub-category</h3>
      {isLoading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            subCategory: "",
          }}
          onSubmit={async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            console.log({
              category: selectedCategory,
              subCategory: values.subCategory,
            });
            console.log("requestint a post");
            if (selectedCategory.length >= 1 && values.subCategory.length >= 1) {
              setIsLoading(true);
              const res = await addNewSubCategory({
                category: selectedCategory,
                newSubCategory: values.subCategory.toLowerCase(),
              }).catch((res) => {
                console.error(res);
                setIsLoading(false);
                toasterService.set({
                  title: "Failed",
                  message: `Failed to add ${values.subCategory}`,
                  type: "warning",
                  toastSize: "toastSmall",
                });
              });
              console.log(res);
              if (res) {
                console.log(`succesfully added subcategory ${values.subCategory}`);
                setIsLoading(false);
                toasterService.set({
                  title: "Success",
                  message: `Succesfully added subcategory "${values.subCategory}"`,
                  type: "success",
                  toastSize: "toastSmall",
                });
              }
            }
          }}>
          <Form>
            {category && (
              <CatergoryDropDown
                label={"Category"}
                options={category}
                selectedCategory={selectedCategory}
                onChange={(e: any) => {
                  console.log(e);
                  setSelectedCategory(e.value);
                }}
              />
            )}
            {subCategory && selectedCategory.length >= 1 && (
              <FormikInputBox
                style={{ textTransform: "lowercase" }}
                id='subCategory'
                type={"text"}
                label={"Sub Category"}
                placeholder={"New Sub Category"}
              />

              // <InputBox
              //
              //   id='subCategory'
              //   type='text'
              //   name='Sub Category'
              //   label={'Sub Category'}
              // />
            )}
            <button
              className='YMformikSumbit'
              type='submit'
              disabled={selectedCategory.length >= 1 ? false : true}>
              Submit
            </button>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default AddSubCategory;
