import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import YMContainer from "../../../components/container/YMContainer";
import { WORK_THUMBNAILS } from "../../services/assets";
import WFM from "../../../components/workFilterMenu/WFM.component";
import { getAllRealEstate, getFilteredData } from "../../../services/api.service";
import { useNavigate } from "react-router-dom";
import { route } from "../../services/route";
// const images: Array<{ src: string }> = [];

function RealEstateWork() {
  const [filteredData, setFilterData] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("architecture");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const navigate = useNavigate();
  const filter = async (data: { selectedCategory: string; selectedSubCategory: string }) => {
    console.log(data);
    console.log("Filtering data=>>");
    const res = await getAllRealEstate().catch((e) => {
      console.log(e);
    });
    console.log(res);
    if (res) setFilterData(res);
  };
  useEffect(() => {
    filter({
      selectedCategory,
      selectedSubCategory,
    });
    return () => {};
  }, []);
  const [filterMobile, setfilterMobile] = useState(false);
  return (
    <YMContainer className='work-container real-estate'>
      <div className='real-estate-title'>
        <h2>Real Estates Model</h2>
        <i className='small'>(Experimental)</i>
        <p>
          Step into the future of real estate with our captivating 3D model. Immerse yourself in
          meticulously designed spaces, showcasing the perfect blend of modern luxury and timeless
          elegance. Explore every detail and envision your dream home in stunning virtual reality.
        </p>
      </div>
      <div className='productContainer'>
        {filteredData
          ? filteredData.map((data: any) => {
              return (
                <div
                  className='productItem'
                  onClick={() => {
                    navigate(`/real-estate/${data._id}`);
                  }}>
                  {data?.media?.images.length >= 1 ? (
                    <img src={data?.media?.images[0]} />
                  ) : (
                    <img src={WORK_THUMBNAILS["work_1"]} />
                  )}
                  <div className='projectcard'>
                    <div className='projectcard__title'>{data?.title}</div>
                    <p className='projectcard__quickinfo'>
                      {data?.description.length >= 10
                        ? data?.description
                        : `${data?.description.slice(0, 10)}...`}
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </YMContainer>
  );
}

export default RealEstateWork;
