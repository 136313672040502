import React, { useEffect, useState } from 'react';
import RadioButton from '../common/RadioButton.component';
import GoTo from '../rightArrow/GoTo.component';
import { useNavigate } from 'react-router-dom';
import { route } from '../../core/services/route';
import { getCategories } from '../../services/api.service';
import { formatText } from '../../services/helper.service';

function WFM({
  className,
  setSelectedFilter,
  selectedFilter,
  setFilterData,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  filter,
  setfilterMobile,
}: any) {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any>({});
  useEffect(() => {
    getCategory();

    return () => {};
  }, []);

  const getCategory = async () => {
    const res: {
      message: string;
      data: any;
    } | void = await getCategories().catch((e) => {
      console.error(e);
    });
    if (res) {
      console.log(res);
      setCategories(res);
    }
  };
  const elementSubCategory = () => {
    let i: any = [];
    Object.keys(categories).map((cn) => {
      if (cn == selectedCategory) {
        categories[cn].map((ssc: any) => {
          console.log(ssc);
          i.push(
            <label className='typeBody'>
              <RadioButton
                name={'type'}
                checked={selectedSubCategory == ssc}
                onClick={() => {
                  setSelectedSubCategory(ssc);
                  filter({
                    selectedSubCategory: ssc,
                    selectedCategory: formatText(selectedCategory),
                  });
                }}
              />
              <div>{formatText(ssc)}</div>
            </label>
          );
        });
      }
    });
    return i;
  };
  return (
    <div className={`${className}`}>
      <div className='WFM-space'>
        <div className='work-close'>
          <div className='filterTitle'>Works</div>
          <div
            className='close'
            onClick={() => {
              setfilterMobile(false);
            }}>
            X
          </div>
        </div>
        <div className='filter'>Filter</div>
        <hr />
        <div className='scope'>
          <div className='scopeTitle'>Category</div>
          {categories
            ? Object.keys(categories).map((categoryName: string) => {
                if (formatText(categoryName).toLowerCase() != 'game development')
                  return (
                    <label className='scopeBody'>
                      <RadioButton
                        onClick={() => {
                          if (selectedCategory != categoryName) {
                            setSelectedCategory(categoryName);
                            setSelectedSubCategory('');
                            filter({
                              selectedSubCategory: '',
                              selectedCategory: formatText(categoryName),
                            });
                          }
                        }}
                        checked={categoryName == selectedCategory}
                        name={'scope'}
                      />
                      <div>{formatText(categoryName)}</div>
                    </label>
                  );
              })
            : null}
        </div>
        <hr />
        <div className='type'>
          <div className='typeTitle'>Sub-Category</div>
          {categories ? elementSubCategory() : <></>}{' '}
          {/* <label className='typeBody'>
            <RadioButton name={'type'} />
            <div>Animation</div>
          </label>{' '}
          <label className='typeBody'>
            <RadioButton name={'type'} />
            <div>Motion image</div>
          </label>{' '}
          <label className='typeBody'>
            <RadioButton name={'type'} />
            <div>Showreel</div>
          </label>{' '}
          <label className='typeBody'>
            <RadioButton name={'type'} />
            <div>Show all</div>
          </label> */}
        </div>
      </div>
      <div className='info'>
        <div>Talk to us about your next projects.</div>
        <GoTo
          onClick={() => {
            navigate(route.REQUEST_A_PROPOSAL);
          }}>
          Request a Proposal
        </GoTo>
      </div>
    </div>
  );
}

export default WFM;
