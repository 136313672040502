import React, { useEffect, useState } from "react";

import {
  CatergoryDropDown,
  SubCatergoryDropDown,
  reactSelectOption,
} from "../../../../components/common/Dropdown.component";
import ArchitectureForm from "./Architecture.form";
import InteriorDesignForm from "./InteriorDesign.form";
import VirtualProductionForm from "./VIrtualProduction.form";
import GoBack from "../../../../components/rightArrow/GoBack.component";
import { route } from "../../../services/route";
import { useNavigate } from "react-router-dom";
import { addNewProject, getCategories } from "../../../../services/api.service";
import { formatText } from "../../../../services/helper.service";
import Loading from "../../../../components/common/Loading.component";
import toasterService from "../../../../components/toast/ToasterService.component";
import ArtAndGameDevForm from "./ArtAndGameDev.form";

function Project() {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [categories, setcategories] = useState<any>({});
  const [category, setCategory] = useState<reactSelectOption | null>(null);
  const [subCategory, setSubCategory] = useState<reactSelectOption | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSubCategory, setSubSelectedCategory] = useState<string>("");
  const [formData, setFormData] = useState<any>({});
  useEffect(() => {
    console.log("from Project");
    console.log(formData);
    return () => {};
  }, [formData]);

  const addProject = async (data: any) => {
    console.log(`Adding a project ${data.category}`);
    setisLoading(true);
    const sure = window.confirm("Are you sure?");
    console.log(sure);
    if (!sure) {
      setisLoading(false);
      toasterService.set({
        title: "Empty Field!",
        message: `[Empty Field] Error While Adding a new Project`,
        type: "error",
        toastSize: "toastSmall",
      });
      return false;
    }
    toasterService.set({
      title: "Adding a new Project",
      message: `Pending`,
      type: "warn",
      toastSize: "toastSmall",
    });
    if (data["media"]["images"].length <= 0) {
      setisLoading(false);
      toasterService.set({
        title: "Images is empty!",
        message: `Images is empty`,
        type: "error",
        toastSize: "toastSmall",
      });
      return false;
    }
    // data['media']['thumbnail'] = data['media']['images'][0];
    data["category"] = selectedCategory.toLowerCase();
    data["subCategory"] = selectedSubCategory.toLowerCase();
    const res = await addNewProject(data).catch((e) => {
      console.error(e);
      toasterService.set({
        title: "Something went wrong!",
        message: `Error While Adding a new Project`,
        type: "error",
        toastSize: "toastSmall",
      });
      setisLoading(false);
    });
    if (res) {
      console.log(res);
      toasterService.set({
        title: "Success!",
        message: `Successfully added a new project.`,
        type: "success",
        toastSize: "toastSmall",
      });
    }
    setisLoading(false);
  };
  const getCategory = async () => {
    const res: any | void = await getCategories().catch((e) => {
      console.error(e);
    });
    if (res) {
      console.log("response");
      setcategories(res);
      console.log(res);
      let c: reactSelectOption = [];
      let s_c: reactSelectOption = [];
      Object.keys(res).map((data: string) => {
        c.push({
          value: formatText(data),
          label: formatText(data),
        });
      });
      setSubCategory(s_c);
      setCategory(c);
    }
  };
  useEffect(() => {
    console.log("updating sub categories");
    console.log(categories);
    console.log(selectedCategory);
    let s_c: reactSelectOption = [];
    // setSubSelectedCategory('');
    Object.keys(categories).map((data: string) => {
      if (selectedCategory == formatText(data))
        categories[data].map((subData: string) => {
          s_c.push({
            value: formatText(subData).replaceAll("_", " "),
            label: formatText(subData).replaceAll("_", " "),
          });
        });
    });
    setSubCategory(s_c);
    return () => {};
  }, [selectedCategory]);
  useEffect(() => {
    console.log(selectedSubCategory);
    return () => {};
  }, [selectedSubCategory]);
  useEffect(() => {
    getCategory();
    return () => {};
  }, []);

  return (
    <div className='createAProject'>
      <GoBack
        onClick={() => {
          navigate(route.ADMIN);
        }}>
        Back
      </GoBack>
      <h3>Add a new project</h3>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {category && (
            <CatergoryDropDown
              label={"Category"}
              options={category}
              selectedCategory={selectedCategory}
              onChange={(e: any) => {
                console.log(e);
                setSelectedCategory(e.value);
              }}
            />
          )}
          {subCategory && selectedCategory.length >= 1 && (
            <SubCatergoryDropDown
              selectedSubCategory={selectedSubCategory}
              onChange={(e: any) => {
                console.log(e);
                setSubSelectedCategory(e.value);
                console.log(selectedSubCategory);
              }}
              label={"Sub Category"}
              options={subCategory}
            />
          )}
          {selectedCategory == "Architecture" && <ArchitectureForm setFormData={addProject} />}
          {selectedCategory == "Interior Design" && <InteriorDesignForm setFormData={addProject} />}
          {selectedCategory == "Visuals and Production" && (
            <VirtualProductionForm setFormData={addProject} />
          )}
          {selectedCategory == "Arts and Game Dev" && (
            <ArtAndGameDevForm setFormData={addProject} />
          )}
          {/* <VirtualProductionForm /> */}
          {/*  */}
          {/* 
      <InputBox  />

   
     
      <InputBox type={'text'} label={'Responsible Person'} />
       */}
        </>
      )}
    </div>
  );
}

export default Project;
