import React, { useEffect, useRef, useState } from "react";
import { VIDEO } from "./services/assets";
import Header from "../components/header/Header.component";
import Footer from "../components/footer/Footer.component";
import YMContainer from "../components/container/YMContainer";
import Home from "./pages/home/Home.page";
import RequestProposal from "./pages/requestAProposal/RequestProposal.page";
import Contact from "./pages/contacts/Contact.page";
import { Route, Routes } from "react-router-dom";
import { route } from "./services/route";
import About from "./pages/about/About.page";
import Service from "./pages/service/Service.page";
import Work from "./pages/works/Work.page";
import Admin from "./pages/admin/Admin.component";
import Toaster from "../components/toast/Toaser.component";
import toasterService from "../components/toast/ToasterService.component";
import Project from "./pages/admin/addAProject/Project.admin";
import AddSubCategory from "./pages/admin/subCategory/AddSubCategory.component";
import ProjectProfile from "./pages/project/ProjectProfile.page";
import Add3DModels from "./pages/admin/add3DModels/Add3DModels.form";
import RealEstateWork from "./pages/works/RealEstate.page";
import RealEstatePage from "./pages/project/RealEstate.page";
import ModelViewer from "../components/common/Bike";
import AdminProjectsTable from "./pages/admin/ProjectsTable";
import Login from "./pages/admin/Login";
import { jwtDecode } from "jwt-decode";
function App() {
  const toasterList = useRef([]);
  const [isAuth, setisAuth] = useState(false);
  useEffect(() => {
    const auth = localStorage.getItem("ymAuth");
    console.log(auth);
    if (auth) {
      let jwtDecoded: any = jwtDecode(auth);
      let timeLeft = jwtDecoded.exp - Math.floor(new Date().getTime() / 1000);
      if (timeLeft >= 1) {
        setisAuth(true);
      } else {
        setisAuth(false);
        localStorage.removeItem("ymAuth");
      }
    }
    toasterService.get().subscribe((data: any) => {
      toasterList.current = data;
    });
  }, []);

  return (
    <>
      <Header />
      <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path={route.HOME} element={<Home />} />
          <Route path={route.REQUEST_A_PROPOSAL} element={<RequestProposal />} />
          <Route path={route.CONTACT} element={<Contact />} />
          <Route path={route.ABOUT} element={<About />} />
          <Route path={route.SERVICE} element={<Service />} />
          <Route path={route.WORKS} element={<Work />} />
          <Route path={route.REAL_ESTATE} element={<RealEstateWork />} />
          <Route path={route.PROJECT} element={<ProjectProfile />} />
          <Route path={route.REAL_STATE_PAGE} element={<RealEstatePage />} />
          <Route path={route.modelViewer} element={<ModelViewer />} />
          <Route
            path={route.ADMIN}
            element={isAuth ? <Admin /> : <Login setisAuth={setisAuth} />}
          />
          <Route path={route.LOGIN} element={<Login setisAuth={setisAuth} />} />
          <Route
            path={route.ADMIN_ADD_PROJECT}
            element={isAuth ? <Project /> : <Login setisAuth={setisAuth} />}
          />
          <Route
            path={route.ADMIN_ADD_SUB_CATEGORY}
            element={isAuth ? <AddSubCategory /> : <Login setisAuth={setisAuth} />}
          />
          <Route
            path={route.ADMIN_ADD_MODEL}
            element={isAuth ? <Add3DModels /> : <Login setisAuth={setisAuth} />}
          />
          <Route
            path={route.VIEW_PROJECT}
            element={isAuth ? <AdminProjectsTable /> : <Login setisAuth={setisAuth} />}
          />
        </Routes>
        <Toaster position='bottom-right' autoDelete={true} toastList={toasterList.current} />
      </div>
      <Footer />
    </>
  );
}

export default App;
