import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import YMContainer from "../../../components/container/YMContainer";
import YouTube, { YouTubeProps } from "react-youtube";
import Footer from "../../../components/footer/Footer.component";
import GeneralFooter from "../../../components/footer/GeneralFooter.component";
import { getProjectDetails } from "../../../services/api.service";
import {
  IArchitecture,
  IInteriorDesign,
  IVirtualProduction,
} from "../../../interfaces/project.interface";
import { formatText } from "../../../services/helper.service";
import ImageGallery from "react-image-gallery";
import parse from "html-react-parser";
import GridImage from "../../../components/common/GridImage";

const img_url =
  "https://ym-imagineers.s3.ap-south-1.amazonaws.com/c62c383a-ac49-4eb9-824d-990d9a982363.png";

function ProjectProfile() {
  const { projectId, category } = useParams();
  const [images, setImages] = useState<Array<{ original: string; thumbnail: string }>>([]);
  const [heroImage, setHeroImage] = useState<any>("");
  const [projectData, setProjectData] = useState<any | null>(null);
  useEffect(() => {
    console.log(projectId, category);
    if (projectId && category) setProjectDetails(projectId, category);
    return () => {};
  }, []);

  const setProjectDetails = async (id: string, category: string) => {
    try {
      const response: IArchitecture | IInteriorDesign | IVirtualProduction =
        await getProjectDetails(id, category);
      console.log(response);
      setProjectData(response);
      if (response.media?.images?.length) {
        let im: Array<{ original: string; thumbnail: string }> = [];
        response.media?.images?.map((url, index) => {
          if (index == 1) {
            setHeroImage(url);
          }
          if (index > 0)
            im.push({
              original: url as string,
              thumbnail: url as string,
            });
        });
        if (response.media?.images?.length == 1) {
          setHeroImage(response.media?.images[0]);
        }
        setImages(im);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "650px",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <div className='projectProfile__container'>
      <div
        className='projectProfile__heroSection'
        style={{
          backgroundImage: `linear-gradient(to top, rgb(0, 0, 0) 2%, transparent 60%), linear-gradient(rgb(0, 0, 0) 2%, transparent 30%), url("${heroImage}")`,
        }}></div>
      <YMContainer className='projectProfile__details'>
        <div className='profileProject_head'>
          <h2 className='__title'>{projectData?.title}</h2>
          {projectData?.description ? (
            <p className='__description'>{parse(projectData?.description)}</p>
          ) : null}
          {projectData?.media?.youTube?.length >= 1 ? (
            <YouTube
              className='projectProfile_ytVideo'
              videoId={projectData?.media?.youTube}
              opts={opts}
              onReady={onPlayerReady}
            />
          ) : null}
          <h2 className='__title'>Project Info</h2>
          <div className='__infos'>
            {projectData?.timeline ? (
              <div>
                <span className='__infos_key'>Timline:</span>
                <span className='__infos_value'>{formatText(projectData?.timeline)}</span>
              </div>
            ) : null}
            {projectData?.category ? (
              <div>
                <span className='__infos_key'>Category:</span>
                <span className='__infos_value'>
                  {projectData ? formatText(projectData?.category) : ""}
                </span>
              </div>
            ) : null}
            {projectData?.subCategory ? (
              <div>
                <span className='__infos_key'>Sub-Category:</span>
                <span className='__infos_value'>
                  {projectData ? formatText(projectData?.subCategory) : ""}
                </span>
              </div>
            ) : null}{" "}
            {projectData?.location ? (
              <div>
                <span className='__infos_key'>Location:</span>
                <span className='__infos_value'>{projectData?.location}</span>
              </div>
            ) : null}{" "}
            {projectData?.plot ? (
              <div>
                <span className='__infos_key'>Plot:</span>
                <span className='__infos_value'>{projectData?.plot}</span>
              </div>
            ) : null}{" "}
            {projectData?.rooms?.bedRooms ? (
              <div>
                <span className='__infos_key'>Number of Bedrooms:</span>
                <span className='__infos_value'>{projectData?.rooms?.bedRooms}</span>
              </div>
            ) : null}{" "}
            {projectData?.rooms?.hall ? (
              <div>
                <span className='__infos_key'>Number of Halls:</span>
                <span className='__infos_value'>{projectData?.rooms?.hall}</span>
              </div>
            ) : null}{" "}
            {projectData?.rooms?.kitchen ? (
              <div>
                <span className='__infos_key'>Number of Kitchen:</span>
                <span className='__infos_value'>{projectData?.rooms?.kitchen}</span>
              </div>
            ) : null}{" "}
            {projectData?.buildingMaterial ? (
              <div>
                <span className='__infos_key'>Building Material:</span>
                <span className='__infos_value'>Kathmandu, Nepal</span>
              </div>
            ) : null}{" "}
            {projectData?.responsiblePerson ? (
              <div>
                <h2 className='__title'>Teams</h2>
                <span className='__infos_key'>Responsible Indivisual:</span>
                <span className='__infos_value'>{projectData?.responsiblePerson}</span>
              </div>
            ) : null}{" "}
            {projectData?.team ? <h2 className='__title'>Teams</h2> : null}
            {projectData?.team
              ? Object.keys(projectData?.team).map((title) => {
                  return (
                    <div>
                      <span className='__infos_key'>{title}:</span>
                      <span className='__infos_value'>{projectData?.team[title]}</span>
                    </div>
                  );
                })
              : null}
          </div>

          <h2 className='__title'>Images</h2>
          {images.length >= 1 ? (
            projectData?.category == "arts and game dev" ? (
              <GridImage images={images} />
            ) : (
              <ImageGallery items={images}></ImageGallery>
            )
          ) : null}
        </div>
      </YMContainer>
    </div>
  );
}

export default ProjectProfile;
